<template>
    <v-row v-if="loading" class="ma-0">
        <v-col cols="1" />
        <v-col cols="3" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="25%" height="20" />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="40%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="60%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="70%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="70%" height="20" />
        </v-col>
        <v-col cols="1" />
    </v-row>
    <v-row v-else class="caption text-uppercase ma-0">
        <v-col cols="1" />
        <v-col cols="3" class="pl-2 pr-0">Title</v-col>
        <v-col cols="1" class="pl-2 pr-0">Type</v-col>
        <v-col cols="2" class="pl-2 pr-0">Publishing status</v-col>
        <v-col v-if="isReview" cols="2" class="pl-2 pr-0">
            <a-sortable-column column="submitted" is-default>
                Submitted
            </a-sortable-column>
        </v-col>
        <v-col v-else cols="2" class="pl-2 pr-0">Last Edited</v-col>
        <v-col cols="2" class="pl-2 pr-0">Publish Date</v-col>
        <v-col cols="1" />
    </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import { ASortableColumn } from '@/components/AList';

const AnnouncementsListHeaderProps = Vue.extend({
    name: 'AnnouncementsListHeader',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        ASortableColumn
    }
})
export default class AnnouncementsListHeader extends AnnouncementsListHeaderProps {
    @InjectReactive({
        from: 'isReview',
        default() {
            return false;
        }
    })
    isReview!: boolean;
}
</script>
