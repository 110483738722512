import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VRow,{staticClass:"a-list-row ma-0 mb-2 white pt-3 pb-3"},[_c(VCol,{staticClass:"pa-0 pl-3 ma-0",attrs:{"cols":"1"}},[_c(VAvatar,{attrs:{"rounded":"","color":"main-background"}},[_c(VIcon,{attrs:{"color":"grey lighten-2","large":""}},[_vm._v("image")])],1)],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"3"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(25, 75) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"1"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(35, 55) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(20, 35) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(50, 65) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(50, 65) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 py-0 ma-auto",attrs:{"cols":"1"}})],1):_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VRow,{staticClass:"a-list-row ma-0 mb-2",class:{
            white: !hover && !_vm.isActive,
            'concrete-solid': hover || _vm.isActive,
            'elevation-2': _vm.hasAction && (hover || _vm.isActive),
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
            'with-action': _vm.hasAction,
            'announcement-archived': _vm.isArchived
        },on:{"click":_vm.onRowAction}},[_c(VCol,{class:{ 'px-0': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"cols":"1"}},[_c('announcement-media',{attrs:{"announcement":_vm.announcement}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",class:{
                'cell-disabled': !_vm.isNameDefined
            },attrs:{"cols":"3"}},[_c('text-truncated',{attrs:{"text":_vm.name}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"1"}},[_c('announcement-type-chip',{attrs:{"announcement":_vm.announcement}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"2"}},[_c('status-chip',{attrs:{"status":_vm.announcement.status_string,"tooltip":_vm.statusTooltip},scopedSlots:_vm._u([(_vm.isDraft)?{key:"icon",fn:function(){return [_c(VIcon,{staticClass:"pl-2 pb-1",attrs:{"x-small":""}},[_vm._v("info-circle")])]},proxy:true}:null],null,true)}),(_vm.isArchived)?_c('status-chip',{attrs:{"status":"Archived"}}):_vm._e()],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"2"}},[_c('text-truncated',{attrs:{"text":_vm.formatDate(_vm.modifiedDate),"tooltip":_vm.formatDateTime(_vm.modifiedDate)}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"2"}},[(_vm.canShowPublishDate)?_c('text-truncated',{attrs:{"text":_vm.formatDate(_vm.announcement.min_distribution_time),"tooltip":_vm.formatDateTime(
                        Number(_vm.announcement.min_distribution_time)
                    )}}):_vm._e()],1),_c(VCol,{staticClass:"pl-2 pr-0 py-0 ma-auto",attrs:{"cols":"1","align":"right"}},[_c('announcement-row-actions',{attrs:{"announcement":_vm.announcement},on:{"open":_vm.markRowActive,"action":_vm.onAction}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }