<template>
    <v-container v-if="isBusy && $vuetify.breakpoint.mdAndUp">
        <announcements-list-header
            v-if="$vuetify.breakpoint.mdAndUp"
            :loading="loading"
        />
        <announcement-row
            v-for="announcement in announcements.length || 10"
            :key="'announcement-row-' + announcement"
            loading
        />
    </v-container>
    <v-container v-else-if="isBusy">
        <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            type="card-heading, list-item-avatar-three-line"
            class="mb-6"
        />
    </v-container>
    <v-container v-else-if="hasData && $vuetify.breakpoint.mdAndUp">
        <announcements-list-header />
        <announcement-row
            v-for="(announcement, index) in announcements"
            :key="'announcement-row-' + announcement.id + '-' + index"
            :announcement="announcement"
            :action="getAction(announcement)"
            @activate="setActiveItem"
            @action="onAction"
        />

        <assignment-comments
            :assignment-id="showCommentsForAnnouncementId"
            @close="hideComments"
            @update="update"
        />

        <assignment-action-dialogs v-on="$listeners" />
    </v-container>
    <v-container v-else-if="hasData" class="px-0">
        <announcement-card
            v-for="(announcement, index) in announcements"
            :key="'announcement-card-' + announcement.id + '-' + index"
            :announcement="announcement"
            :action="getAction(announcement)"
            class="mb-2"
            flat
            style="cursor: pointer"
            @action="onAction"
        />

        <assignment-comments
            :assignment-id="showCommentsForAnnouncementId"
            @close="hideComments"
            @update="update"
        />

        <assignment-action-dialogs v-on="$listeners" />
    </v-container>
    <a-message-bar v-else>No Announcements Created Yet</a-message-bar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { ProvideReactive } from '@/utils/decorators';

import { AnnouncementCard } from '@/components/Announcements/AnnouncementCard';
import { AMessageBar } from '@/components/AMessageBar';

import AnnouncementActionsFactory from '@/components/Announcements/AnnouncementActions/AnnouncementActionsFactory';

import { AssignmentComments } from '@/components/Authoring/AssignmentComments';

import { AssignmentActionDialogs } from '@/components/Authoring/AssignmentActions/AssignmentActionDialogs';

import type { Announcement } from '@/types/Announcement';
import type { AuthorRequest } from '@/types/AuthorRequest';

import AnnouncementsListHeader from './AnnouncementsListHeader.vue';
import AnnouncementRow from './AnnouncementRow.vue';

const AnnouncementsListProps = Vue.extend({
    name: 'AnnouncementsList',
    props: {
        announcements: {
            type: Array as PropType<Partial<Announcement>[]>,
            default(): Announcement[] {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        },
        allowArchive: {
            type: Boolean,
            default() {
                return true;
            }
        },
        isReview: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AnnouncementsListHeader,
        AnnouncementRow,
        AssignmentComments,
        AssignmentActionDialogs,
        AnnouncementCard,
        AMessageBar
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AnnouncementsList extends AnnouncementsListProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    showCommentsForAnnouncementId = 0;
    // Action dialogs inject that
    @ProvideReactive() request: Partial<AuthorRequest> | null = null;

    announcement: Partial<Announcement> | null = null;
    // forward refs down the tree
    @ProvideReactive('allowArchive')
    get allowArchiveProvider() {
        return this.allowArchive;
    }
    @ProvideReactive('isReview')
    get isReviewProvider() {
        return this.isReview;
    }

    get hasData() {
        return Boolean(this.announcements.length);
    }

    get isBusy() {
        return this.loading || this.updating;
    }

    get isAuthoringTeamUser() {
        return this.isEditor || this.isAuthor;
    }

    setActiveItem(announcement: Partial<Announcement>) {
        this.announcement = announcement;

        this.request =
            announcement.author_request_assignment?.author_request || null;
    }

    onAction(
        action: string,
        announcement: Partial<Announcement>,
        payload: any // eslint-disable-line @typescript-eslint/no-explicit-any
    ) {
        if (action === 'archive') {
            this.tryArchive(announcement, payload);
        }

        if (action === 'show-comments') {
            this.showComments(announcement);
        }
    }

    async tryArchive(announcement: Partial<Announcement>, archive: boolean) {
        return this.archive(announcement, archive).then(data => {
            if (!data.meta?.success) {
                this.$store.dispatch(
                    'notification/error',
                    `Amp could not be ${
                        archive ? 'archived' : 'unarchived'
                    }. Please try again or contact support.`
                );

                return false;
            }

            this.$store.dispatch(
                'notification/success',
                `Amp ${archive ? 'archived' : 'unarchived'}`
            );

            this.update();
        });
    }

    async archive(announcement: Partial<Announcement>, archive = true) {
        return this.$http
            .post(`/announcements/archive/${announcement.id}`, {
                archived: archive
            })
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    getAction(announcement: Partial<Announcement>) {
        const AuthoringActions = new AnnouncementActionsFactory(
            announcement,
            false,
            this.isAuthoringTeamUser
        );

        const actions = AuthoringActions.getActions();
        const editAction = actions.find(action => action.action === 'edit');
        const reviewAction = actions.find(action => action.action === 'review');

        return editAction || reviewAction;
    }

    showComments(announcement: Partial<Announcement>) {
        if (announcement.author_request_assignment?.id) {
            this.showCommentsForAnnouncementId =
                announcement.author_request_assignment?.id;
        }
    }

    hideComments() {
        this.showCommentsForAnnouncementId = 0;
    }

    update() {
        this.$emit('update', true);
    }

    isLimitedDistribution(announcement: Partial<Announcement>) {
        return announcement.press_release?.limited_distribution_only ?? false;
    }
}
</script>
