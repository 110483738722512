import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"offset-y":"","nudge-left":"15","nudge-bottom":"5","close-on-content-click":false},on:{"input":_vm.onMenuToggle},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"my-n2 filter-button",class:{ active: _vm.hasFiltersApplied },attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("filter")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.values),function(filter,i){return _c(VListItem,{key:i},[_c(VCheckbox,{staticClass:"mt-0 pt-0 filter-option",attrs:{"label":filter.label,"hide-details":""},model:{value:(filter.checked),callback:function ($$v) {_vm.$set(filter, "checked", $$v)},expression:"filter.checked"}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }