<template>
    <a @click.prevent="sortBy">
        <slot></slot>
        <v-icon
            v-if="isOrderedBy && !isDesc"
            small
            color="primary-color"
            class="pl-2"
        >
            caret-up
        </v-icon>
        <v-icon
            v-else-if="isOrderedBy && isDesc"
            small
            color="primary-color"
            class="pl-2 mt-n1"
        >
            caret-down
        </v-icon>
    </a>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const ASortableColumnProps = Vue.extend({
    name: 'ASortableColumn',
    props: {
        column: {
            type: String,
            default() {
                return '';
            }
        },
        isDefault: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class ASortableColumn extends ASortableColumnProps {
    get orderBy() {
        return this.$route.query.sort;
    }

    get isDesc() {
        return this.$route.query.asc === '0';
    }

    get isOrderedBy() {
        return (
            this.orderBy === this.column || (!this.orderBy && this.isDefault)
        );
    }

    sortBy() {
        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                page: void 0, // every sorting should reset paging, if any
                sort: this.column,
                asc: this.isDesc ? '1' : '0'
            }
        });
    }
}
</script>
