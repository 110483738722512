<template>
    <v-menu
        offset-y
        nudge-left="15"
        nudge-bottom="5"
        :close-on-content-click="false"
        @input="onMenuToggle"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                small
                icon
                v-bind="attrs"
                class="my-n2 filter-button"
                :class="{ active: hasFiltersApplied }"
                v-on="on"
            >
                <v-icon x-small>filter</v-icon>
            </v-btn>
        </template>

        <v-list dense>
            <v-list-item v-for="(filter, i) in values" :key="i">
                <v-checkbox
                    v-model="filter.checked"
                    :label="filter.label"
                    class="mt-0 pt-0 filter-option"
                    hide-details
                />
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue, { PropType } from 'vue';

import { Filter } from '@/components/AList';
import { Location } from 'vue-router';

const AFilterableColumnProps = Vue.extend({
    name: 'AFilterableCoumn',
    props: {
        filters: {
            type: Array as PropType<Filter[]>,
            default() {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class AFilterableColumn extends AFilterableColumnProps {
    get values() {
        return this.filters.map(filter => {
            const value = { ...filter };

            if (typeof value.checked === 'undefined') {
                value.checked = this.isFilteredBy(value.queryKey);
            }

            return value;
        });
    }

    get hasFiltersApplied() {
        return this.values.some(
            value => typeof this.$route.query[value.queryKey] !== 'undefined'
        );
    }

    onMenuToggle(open: boolean) {
        if (!open) {
            this.applyFilters();
        }
    }

    applyFilters() {
        const query = this.getQuery();

        if (this.hasChanges(query)) {
            this.$router.push(this.getRoute(query));
        }
    }

    hasChanges(query: Location['query']) {
        const { route } = this.$router.resolve(this.getRoute(query));

        return route.fullPath !== this.$route.fullPath;
    }

    isFilteredBy(key: string) {
        return this.$route.query[key] === '1';
    }

    getQuery() {
        const filters = this.values.map(filter => ({
            [filter.queryKey]: filter.checked ? '1' : void 0
        }));

        return {
            ...this.$route.query,
            ...Object.assign({}, ...filters),
            page: void 0
        };
    }

    getRoute(query: Location['query']) {
        return {
            path: this.$route.path,
            query
        };
    }
}
</script>

<style lang="scss" scoped>
.filter-button.active {
    background-color: $faded-blue;
}

.filter-option::v-deep {
    .v-label {
        color: $secondary-color;
    }
}
</style>
