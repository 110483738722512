<template>
    <v-badge
        left
        overlap
        avatar
        :offset-x="badgeOffset"
        :value="isActive"
        color="white"
        class="a-resource-media"
    >
        <template #badge>
            <v-tooltip v-if="isPremium" bottom>
                <template #activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" color="white" v-on="on">
                        <v-icon color="turquoise">gem</v-icon>
                    </v-avatar>
                </template>
                <span>Premium Distribution</span>
            </v-tooltip>
            <v-tooltip v-if="isFinancial" bottom>
                <template #activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" color="white" v-on="on">
                        <v-icon color="purple lighten-1">
                            fa-comment-dollar
                        </v-icon>
                    </v-avatar>
                </template>
                <span>Financial Distribution</span>
            </v-tooltip>
            <v-tooltip v-if="isMsn" bottom>
                <template #activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" color="white" v-on="on">
                        <v-icon color="msn-blue">$vuetify.icons.msn</v-icon>
                    </v-avatar>
                </template>
                <span>MSN Distribution</span>
            </v-tooltip>
            <v-tooltip v-if="isGuestPostPremium" bottom>
                <template #activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" color="white" v-on="on">
                        <v-icon color="red">fire-flame-curved</v-icon>
                    </v-avatar>
                </template>
                <span>B.L.O.W. Distribution</span>
            </v-tooltip>
        </template>
        <v-avatar rounded :size="size" color="main-background">
            <v-img v-if="logo" :src="logo" />
            <v-icon v-else color="grey lighten-2" large>image</v-icon>
        </v-avatar>
        <v-tooltip v-if="hasNewComments" bottom z-index="10">
            <template #activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    class="new-comments--circle new-comments--circle-outer"
                    v-on="on"
                ></div>
            </template>
            <span>New comments in the last 48 hours</span>
        </v-tooltip>
    </v-badge>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import { PrEvents } from '@/mixins';

import type { Announcement } from '@/types/Announcement';

const AnnouncementMediaProps = Vue.extend({
    name: 'AnnouncementMedia',
    props: {
        announcement: {
            type: Object as PropType<Partial<Announcement>>,
            default(): Announcement | null {
                return null;
            }
        },
        size: {
            type: Number,
            default() {
                return 48;
            }
        }
    }
});

@Component
export default class AnnouncementMedia extends mixins(
    AnnouncementMediaProps,
    PrEvents
) {
    get logo() {
        if (this.announcement) {
            const media = this.announcement?.press_release?.media_resources;

            if (media?.length) {
                return media[0].media_file.relative_filename;
            }
        }
        return false;
    }

    get isActive() {
        return Boolean(
            this.isPremium ||
                this.isFinancial ||
                this.isMsn ||
                this.isGuestPostPremium
        );
    }

    get isPremium() {
        return Boolean(
            this.announcement?.is_premium ||
                this.announcement?.press_release?.is_premium
        );
    }

    get isFinancial() {
        return this.announcement?.is_financial;
    }

    get isMsn() {
        return Boolean(this.announcement?.is_msn);
    }

    get isGuestPostPremium() {
        return Boolean(this.announcement?.is_guest_post_premium);
    }

    get isAuthoredAndPremium() {
        return this.isPremium && this.announcement?.author_request_assignment;
    }

    get isAuthoredAndFinancial() {
        return this.isFinancial && this.announcement?.author_request_assignment;
    }

    get hasNewComments() {
        const events = this.announcement?.press_release?.pr_events;

        if (!events) {
            return false;
        }

        return this.getNewCommentsForLast48Hours(events);
    }

    get badgeOffset() {
        let offset = 0;

        if (this.isActive) {
            const activeStates = [
                this.isPremium,
                this.isFinancial,
                this.isMsn,
                this.isGuestPostPremium
            ].filter(Boolean);

            if (activeStates.length > 1) {
                offset += 10 + (activeStates.length - 1) * 20;
            }
        }

        return offset;
    }
}
</script>

<style lang="scss">
@import '@/components/AResourceMedia/AResourceMedia.scss';
</style>
