<template>
    <v-card
        v-if="loading"
        class="amp-card amp-card--top-border unapproved pt-3 pl-2"
        style="padding-bottom: 1px"
    >
        <v-skeleton-loader
            type="heading"
            style="margin-top: -2px"
            :width="randomBetween(25, 55) + '%'"
        />
        <v-list-item three-line class="pa-0 pl-2">
            <v-avatar rounded size="100" color="main-background">
                <v-icon color="grey lighten-2" large>image</v-icon>
            </v-avatar>
            <v-list-item-content class="mt-2">
                <v-skeleton-loader
                    class="pl-4"
                    type="heading"
                    :width="randomBetween(5, 15) + '%'"
                />
                <v-skeleton-loader
                    type="list-item-two-line"
                    :width="randomBetween(35, 55) + '%'"
                />
            </v-list-item-content>
        </v-list-item>
    </v-card>
    <v-card
        v-else
        class="amp-card amp-card--top-border"
        :class="rootClass"
        @[actionBinder]="onAction"
    >
        <div class="status-bar mt-2 ml-2">
            <status-chip :status="status" :tooltip="statusTooltip" with-icon>
                <template v-if="isDraft" #icon>
                    <v-icon class="pl-2 pb-1" x-small>info-circle</v-icon>
                </template>
            </status-chip>

            <status-chip
                v-if="isLimitedDistribution"
                status="Selective Distribution"
            />

            <status-chip
                v-if="isAuthored"
                tooltip="Authored amp"
                class="float-right mr-2"
                color="transparent"
                dense
            >
                <template #icon>
                    <v-icon small>pen-nib</v-icon>
                </template>
            </status-chip>

            <status-chip v-if="isArchived" status="Archived" />
        </div>

        <v-list-item three-line>
            <announcement-media
                :announcement="announcement"
                :size="100"
                class="ma-4 ml-0"
            />

            <v-list-item-content class="mt-2">
                <v-list-item-title
                    class="headline text-body-1 align-self-start"
                >
                    <text-truncated :text="name" />
                </v-list-item-title>

                <v-list-item-subtitle
                    class="align-self-end text-right amp-card--date mt-2"
                >
                    <span v-if="isCreated">
                        Created {{ formatDate(createdDate) }}
                    </span>

                    <span v-else-if="isModified">
                        Last Edited {{ formatDate(modifiedDate) }}
                    </span>

                    <span v-else-if="isPending">
                        Pending Publishing {{ formatDate(publishedDate) }}
                    </span>

                    <span v-else-if="isFullyPublished">
                        Published {{ formatDate(publishedDate) }}
                    </span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import {
    snakeCase,
    formatDate,
    randomBetween,
    isTextSelectionEvent
} from '@/utils/helpers';

import { StatusChip } from '@/components/StatusChip';
import { TextTruncated } from '@/components/TextTruncated';
import { AnnouncementMedia } from '@/components/Announcements/AnnouncementMedia';

import type { Announcement } from '@/types/Announcement';
import type { AnnouncementAction } from '@/components/Announcements/AnnouncementActions/AnnouncementActionsFactory';

const AnnouncementCardProps = Vue.extend({
    props: {
        announcement: {
            type: Object as PropType<Partial<Announcement>>,
            default(): Announcement | null {
                return null;
            }
        },
        action: {
            type: Object as PropType<AnnouncementAction>,
            default(): AnnouncementAction | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        StatusChip,
        TextTruncated,
        AnnouncementMedia
    }
})
export default class AnnouncementCard extends AnnouncementCardProps {
    get status() {
        return this.announcement?.status_string || '';
    }

    get statusTooltip() {
        return this.isDraft
            ? 'This Amp has modules that have not been published'
            : '';
    }

    get isDraft() {
        return (
            this.announcement?.has_pending_status &&
            this.announcement?.has_draft_status
        );
    }

    get rootClass() {
        return [snakeCase(this.status), 'd-flex', 'flex-column', 'flex-grow-1']
            .filter(Boolean)
            .join(' ');
    }

    get name() {
        return this.announcement?.name || '';
    }

    get isAuthored() {
        return (
            this.announcement?.author_request_assignment?.author_request &&
            !this.announcement?.author_request_assignment.author_request
                .rejected_time
        );
    }

    get isLimitedDistribution() {
        return (
            this.announcement?.press_release?.limited_distribution_only ?? false
        );
    }

    get isCreated() {
        return this.announcement?.status === 0 && !this.modifiedDate;
    }

    get isModified() {
        return this.announcement?.status === 0 && this.modifiedDate;
    }

    get isPending() {
        return this.announcement?.is_pending;
    }

    get isFullyPublished() {
        return this.announcement?.is_fully_published;
    }

    get createdDate() {
        if (this.announcement?.press_release) {
            return this.announcement?.press_release.created;
        }

        return this.announcement?.created;
    }

    get modifiedDate() {
        if (!this.announcement?.press_release) {
            return '';
        }

        return this.announcement?.press_release.modified;
    }

    get publishedDate() {
        return this.announcement?.min_distribution_time;
    }

    get hasAction() {
        return Boolean(this.action);
    }

    get actionBinder() {
        return this.hasAction ? 'click' : 'nonce';
    }

    get isArchived() {
        return this.announcement?.archived;
    }

    formatDate = formatDate;
    randomBetween = randomBetween;

    onAction(e: Event) {
        if (this.hasAction && !isTextSelectionEvent(e)) {
            const result = this.action.callback(
                this,
                this.announcement.id,
                this.announcement
            );

            if (result) {
                if (this.action?.link) {
                    window.location.href = result;
                }

                if (this.action?.route) {
                    this.$router.push(result);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/ACard/ACard.scss';
</style>
