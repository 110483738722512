/* eslint-disable complexity */
import Vue from 'vue';

import type { Announcement } from '@/types/Announcement';

export type AnnouncementAction = {
    name: string;
    icon?: string;
    iconText?: string;
    action: string;
    link?: boolean;
    route?: boolean;
    callback: (
        context: Vue,
        id?: number,
        announcement?: Partial<Announcement>
    ) => void | string;
};

const actions: Record<string, AnnouncementAction> = {
    review: {
        name: 'Comments',
        icon: 'comment',
        action: 'review',
        callback(context, id, announcement) {
            context.$emit('action', 'show-comments', announcement);
        }
    },
    edit: {
        name: 'Edit',
        icon: 'edit',
        action: 'edit',
        route: true,
        callback(_context, id) {
            // don't define target module in the link, use routes.ts
            return `/announcements/edit/${id}`;
        }
    },
    review_amp: {
        name: 'Review',
        icon: 'eye',
        action: 'review',
        route: true,
        callback(_context, id) {
            // don't define target module in the link, use routes.ts
            return `/announcements/review/${id}`;
        }
    },
    report: {
        name: 'Report',
        icon: 'file',
        action: 'report',
        route: true,
        callback(_context, id) {
            return `/announcements/report/${id}`;
        }
    },
    archive: {
        name: 'Archive',
        icon: 'box-archive',
        action: 'archive',
        callback(context) {
            context.$emit('action', 'archive', true);
        }
    },
    activate: {
        name: 'Activate',
        icon: 'inbox',
        action: 'activate',
        callback(context) {
            context.$emit('action', 'archive', false);
        }
    }
};

export default class AnnouncementActionsFactory {
    announcement: Partial<Announcement>;
    allowArchive = true;
    isAuthoringTeamUser = false;

    constructor(
        announcement: Partial<Announcement>,
        allowArchive = true,
        isAuthoringTeamUser = false
    ) {
        this.announcement = announcement;
        this.allowArchive = allowArchive;
        this.isAuthoringTeamUser = isAuthoringTeamUser;
    }

    get isAuthored() {
        return (
            this.announcement?.author_request_assignment?.author_request &&
            !this.announcement?.author_request_assignment.author_request
                .rejected_time
        );
    }

    get isPending() {
        return (
            this.announcement.has_pending_status ||
            this.announcement.has_published_status
        );
    }

    get isCompleted() {
        return this.announcement.is_fully_published;
    }

    get isAwaitingClient() {
        return this.announcement.is_awaiting_client;
    }

    getUniqueActions() {
        // use Set to provide more consistency for future actions
        const availableActions = new Set<AnnouncementAction>();

        if (
            !this.announcement.is_in_author_process ||
            this.isAuthoringTeamUser
        ) {
            availableActions.add(
                this.isAwaitingClient ? actions.review_amp : actions.edit
            );
        } else {
            availableActions.add(actions.review);
        }

        if (this.isPending || this.isCompleted) {
            availableActions.add(actions.report);
        }

        if (this.announcement.author_request_assignment) {
            availableActions.add(actions.review);
        }

        if (this.allowArchive) {
            if (this.announcement.archived) {
                availableActions.add(actions.activate);
            } else {
                availableActions.add(actions.archive);
            }
        }

        return availableActions;
    }

    getActions() {
        return Array.from(this.getUniqueActions()).filter(Boolean);
    }

    hasAction(action: string) {
        const found = this.getActions().find(
            available => available.action === action
        );
        return !!found;
    }
}
