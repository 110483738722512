import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.hasPages)?_c(VContainer,{staticClass:"amp-pagination",class:{ 'paging-loading': _vm.loading }},[_c(VRow,{staticClass:"text-caption"},[_c(VCol,{staticClass:"text-uppercase text-center text-md-left",attrs:{"cols":"12","md":"3","align-self":"center"}},[_vm._v(" Showing "+_vm._s(_vm.range)+" Of "+_vm._s(_vm.total)+" Items ")]),_c(VCol,{staticClass:"px-0",attrs:{"cols":"12","md":"6","align-self":"center"}},[_c(VPagination,{attrs:{"disabled":_vm.loading,"length":_vm.pages,"total-visible":_vm.size,"color":"secondary","circle":""},on:{"input":_vm.navigate},model:{value:(_vm.paginator.page),callback:function ($$v) {_vm.$set(_vm.paginator, "page", $$v)},expression:"paginator.page"}})],1),_c(VCol,{staticClass:"text-uppercase text-center text-md-right",attrs:{"cols":"12","md":"3","align-self":"center"}},[_vm._v(" Showing "),_c(VSelect,{staticClass:"d-inline-block select--wrap-width px-1 ma-0",attrs:{"items":_vm.paginator.options,"disabled":_vm.loading,"loading":_vm.loading,"hide-details":"","dense":""},on:{"input":_vm.update},model:{value:(_vm.showing),callback:function ($$v) {_vm.showing=$$v},expression:"showing"}}),_vm._v(" Per Page ")],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }