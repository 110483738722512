<template>
    <v-container
        v-if="hasPages"
        class="amp-pagination"
        :class="{ 'paging-loading': loading }"
    >
        <v-row class="text-caption">
            <v-col
                cols="12"
                md="3"
                align-self="center"
                class="text-uppercase text-center text-md-left"
            >
                Showing {{ range }} Of {{ total }} Items
            </v-col>

            <v-col cols="12" md="6" align-self="center" class="px-0">
                <v-pagination
                    v-model="paginator.page"
                    :disabled="loading"
                    :length="pages"
                    :total-visible="size"
                    color="secondary"
                    circle
                    @input="navigate"
                />
            </v-col>

            <v-col
                cols="12"
                md="3"
                align-self="center"
                class="text-uppercase text-center text-md-right"
            >
                Showing
                <v-select
                    v-model="showing"
                    :items="paginator.options"
                    :disabled="loading"
                    :loading="loading"
                    class="d-inline-block select--wrap-width px-1 ma-0"
                    hide-details
                    dense
                    @input="update"
                />
                Per Page
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { isLocalStorageAvailable } from '@/utils/helpers';

const PagingProps = Vue.extend({
    props: {
        total: {
            type: Number,
            default() {
                return 0;
            }
        },
        defaultPerPage: {
            type: Number,
            default() {
                return 10;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        size: {
            type: [Number, String],
            default() {
                return 5;
            }
        }
    }
});

@Component
export default class Paging extends PagingProps {
    paginator = {
        page: 0,
        showing: this.defaultPerPage,
        options: [10, 20, 50, 100]
    };

    isLocalStorageAvailable = isLocalStorageAvailable();

    get showing() {
        return this.paginator.showing;
    }

    set showing(value: number) {
        this.lastUsedPerPage = value;
        this.paginator.showing = value;
    }

    get page() {
        return Number(this.$route.query.page) || 1;
    }

    get pages() {
        return Math.ceil(this.total / this.perPage);
    }

    get hasPages() {
        return this.pages > 0;
    }

    get perPage() {
        return (
            Number(this.$route.query.limit) ||
            this.lastUsedPerPage ||
            this.defaultPerPage
        );
    }

    get range() {
        const start = (this.page - 1) * this.perPage + 1;

        if (start > this.total) {
            return this.total;
        }

        const end = start + this.perPage - 1;

        if (end > this.total) {
            return `${start} - ${this.total}`;
        }

        return `${start} - ${end}`;
    }

    get storageName() {
        return `paging-for${this.$route.path}`.replace(/\//g, '-');
    }

    get lastUsedPerPage() {
        if (this.isLocalStorageAvailable) {
            return Number(localStorage[this.storageName]) || 0;
        }

        return 0;
    }

    set lastUsedPerPage(value: number) {
        if (this.isLocalStorageAvailable) {
            localStorage[this.storageName] = value;
        }
    }

    created() {
        this.$watch(
            () => this.$route.query,
            (current, prev) => {
                if (
                    typeof current.page === 'undefined' &&
                    this.paginator.page !== 1
                ) {
                    // page param was reset explicitly - sync it
                    this.paginator.page = 1;

                    return;
                }

                if (current.page === prev.page && this.paginator.page !== 1) {
                    // some query param has changed while being not on the first page - reset page to the first one
                    this.paginator.page = 1;
                }
            }
        );
    }

    mounted() {
        this.paginator.page = this.page;
        this.showing = this.perPage;

        this.update(this.showing, true);
    }

    navigate(page: number) {
        this.$router.push({
            query: {
                ...this.$route.query,
                page: page === 1 ? void 0 : `${page}`
            }
        });
    }

    update(perPage: number, replace = false) {
        const newLimit =
            perPage === this.defaultPerPage ? void 0 : `${perPage}`;

        if (newLimit !== this.$route.query.limit) {
            this.$router[replace ? 'replace' : 'push']({
                query: {
                    ...this.$route.query,
                    page: void 0,
                    limit: newLimit
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.amp-pagination::v-deep {
    .v-pagination.theme--light {
        .v-pagination__item {
            font-size: 100%;
        }

        .v-pagination__item:not(.v-pagination__item--active) {
            &:hover {
                background-color: $primary-color;
                color: $white;
            }
        }

        .v-pagination__navigation {
            box-shadow: none;
            &:hover {
                background-color: $primary-color;
                .v-icon {
                    color: $white;
                }
            }
        }
    }

    .v-input {
        font-size: 100%;
    }

    &.main-background .v-pagination.theme--light {
        .v-pagination__navigation {
            background: transparent;
            &:hover {
                background-color: $primary-color;
                .v-icon {
                    color: $white;
                }
            }
        }
    }

    .select--wrap-width {
        max-width: min-content !important;
        padding: 0;
        margin: 0;
    }
}
</style>
