<template>
    <span>
        <status-chip
            v-if="isLimitedDistribution"
            status="Selective Distribution"
            label="SD"
            color="turquoise"
            text-color="white"
            dense
        />

        <status-chip
            v-if="isAuthored"
            tooltip="Authored amp"
            color="transparent"
            dense
        >
            <template #icon>
                <v-icon small>pen-nib</v-icon>
            </template>
        </status-chip>
    </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { StatusChip } from '@/components/StatusChip';

import type { Announcement } from '@/types/Announcement';

const AnnouncementTypeChipProps = Vue.extend({
    name: 'AnnouncementTypeChip',
    props: {
        announcement: {
            type: Object as PropType<Partial<Announcement>>,
            default(): Announcement | null {
                return null;
            }
        }
    }
});

@Component({
    components: {
        StatusChip
    }
})
export default class AnnouncementTypeChip extends AnnouncementTypeChipProps {
    get isLimitedDistribution() {
        return (
            this.announcement.press_release?.limited_distribution_only ?? false
        );
    }

    get isAuthored() {
        return (
            this.announcement?.author_request_assignment?.author_request &&
            !this.announcement?.author_request_assignment.author_request
                .rejected_time
        );
    }
}
</script>
