<template>
    <v-row v-if="loading" class="a-list-row ma-0 mb-2 white pt-3 pb-3">
        <v-col cols="1" class="pa-0 pl-3 ma-0">
            <v-avatar rounded color="main-background">
                <v-icon color="grey lighten-2" large>image</v-icon>
            </v-avatar>
        </v-col>
        <v-col cols="3" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(25, 75) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(35, 55) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 35) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(50, 65) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(50, 65) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 py-0 ma-auto" />
    </v-row>
    <v-hover v-else v-slot="{ hover }">
        <v-row
            class="a-list-row ma-0 mb-2"
            :class="{
                white: !hover && !isActive,
                'concrete-solid': hover || isActive,
                'elevation-2': hasAction && (hover || isActive),
                'px-2': $vuetify.breakpoint.mdAndDown,
                'with-action': hasAction,
                'announcement-archived': isArchived
            }"
            @click="onRowAction"
        >
            <v-col cols="1" :class="{ 'px-0': $vuetify.breakpoint.mdAndDown }">
                <announcement-media :announcement="announcement" />
            </v-col>
            <v-col
                cols="3"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !isNameDefined
                }"
            >
                <text-truncated :text="name" />
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 ma-auto">
                <announcement-type-chip :announcement="announcement" />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                <status-chip
                    :status="announcement.status_string"
                    :tooltip="statusTooltip"
                >
                    <template v-if="isDraft" #icon>
                        <v-icon class="pl-2 pb-1" x-small>info-circle</v-icon>
                    </template>
                </status-chip>
                <status-chip v-if="isArchived" status="Archived" />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                <text-truncated
                    :text="formatDate(modifiedDate)"
                    :tooltip="formatDateTime(modifiedDate)"
                />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                <text-truncated
                    v-if="canShowPublishDate"
                    :text="formatDate(announcement.min_distribution_time)"
                    :tooltip="
                        formatDateTime(
                            Number(announcement.min_distribution_time)
                        )
                    "
                />
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 py-0 ma-auto" align="right">
                <announcement-row-actions
                    :announcement="announcement"
                    @open="markRowActive"
                    @action="onAction"
                />
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import {
    isTextSelectionEvent,
    formatDate,
    formatDateTime,
    randomBetween
} from '@/utils/helpers';

import { TextTruncated } from '@/components/TextTruncated';
import { StatusChip } from '@/components/StatusChip';
import { AnnouncementMedia } from '@/components/Announcements/AnnouncementMedia';
import AnnouncementTypeChip from './AnnouncementTypeChip.vue';
import AnnouncementRowActions from './AnnouncementRowActions.vue';

import type { Announcement } from '@/types/Announcement';
import type { AnnouncementAction } from '@/components/Announcements/AnnouncementActions/AnnouncementActionsFactory';

const AnnouncementRowProps = Vue.extend({
    name: 'AnnouncementRow',
    props: {
        announcement: {
            type: Object as PropType<Partial<Announcement>>,
            default(): Announcement | null {
                return null;
            }
        },
        action: {
            type: Object as PropType<AnnouncementAction>,
            default(): AnnouncementAction | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AnnouncementRowActions,
        AnnouncementTypeChip,
        AnnouncementMedia,
        TextTruncated,
        StatusChip
    }
})
export default class AnnouncementRow extends AnnouncementRowProps {
    isActive = false;

    get name() {
        return this.announcement?.name || 'No Headline Available';
    }

    get isNameDefined() {
        return this.announcement?.name;
    }

    get statusTooltip() {
        return this.announcement?.has_pending_status &&
            this.announcement?.has_draft_status
            ? 'This Amp has modules that have not been published'
            : '';
    }

    get modifiedDate() {
        if (!this.announcement?.press_release) {
            return '';
        }

        return this.announcement?.press_release.modified;
    }

    get isArchived() {
        return this.announcement?.archived;
    }

    get isDraft() {
        return (
            this.announcement?.has_pending_status &&
            this.announcement?.has_draft_status
        );
    }

    get hasAction() {
        return Boolean(this.action);
    }

    get canShowPublishDate() {
        return (
            this.announcement.min_distribution_time &&
            ['Published', 'Partially Published'].includes(
                this.announcement.status_string as string
            )
        );
    }

    formatDate = formatDate;
    formatDateTime = formatDateTime;
    randomBetween = randomBetween;

    markRowActive(isActive: boolean) {
        this.isActive = isActive;

        this.$emit('activate', this.announcement);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onAction(action: string, data: any) {
        this.$emit('action', action, this.announcement, data);
    }

    onRowAction(e: Event) {
        if (this.hasAction && !isTextSelectionEvent(e)) {
            const result = this.action.callback(
                this,
                this.announcement.id,
                this.announcement
            );

            if (result) {
                if (this.action.link) {
                    window.location.href = result;
                }

                if (this.action.route) {
                    this.$router.push(result);
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/components/AList/AList.scss';
</style>
